import React from 'react';
import PasswordChange from 'gatsby-theme-q3/src/pages/password-change';
import FormContainer from '../../components/FormContainer';
import HydrationFix from '../../components/HydrationFix';

const CustomPasswordChange = (props) => (
  <HydrationFix>
    <FormContainer>
      <PasswordChange {...props} />
    </FormContainer>
  </HydrationFix>
);

export default CustomPasswordChange;
